import React from 'react';

import { intlShape, FormattedMessage } from '../../../../../util/reactIntl';
import { propTypes } from '../../../../../util/types';
import { formatMoney } from '../../../../../util/currency';

import { humanizeAddOnLineItemCode } from '../../../../../util/data';

import css from '../../../OrderBreakdown.module.css';

const AddOnPriceBreakdown = props => {
  const { lineItems, intl } = props;

  const quantity = lineItems[0].quantity.toString();

  return (
    <ul>
      {lineItems.map((lineItem, i) => {
        const label = humanizeAddOnLineItemCode(lineItem.code);

        return (
          <li className={css.lineItem} key={lineItem.code}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>
              <FormattedMessage
                id={'OrderBreakdown.baseAddOnQuantity'}
                values={{
                  unitPrice: formatMoney(intl, lineItem.unitPrice),
                  quantity,
                }}
              />
            </span>
          </li>
        );
      })}
    </ul>
  );
};

AddOnPriceBreakdown.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default AddOnPriceBreakdown;
