import React, { useEffect, useState } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import JSZip from 'jszip';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { findRouteByRouteName } from '../../util/routes';
import { IconSpinner, IconCheckmark } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { useIntl } from '../../util/reactIntl';

import css from './Button.module.css';

const IconUploadFile = () => (
  <svg
    width="14"
    height="14"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 374.116 374.116"
  >
    <g>
      <path
        d="M344.058,207.506c-16.568,0-30,13.432-30,30v76.609h-254v-76.609c0-16.568-13.432-30-30-30c-16.568,0-30,13.432-30,30
 v106.609c0,16.568,13.432,30,30,30h314c16.568,0,30-13.432,30-30V237.506C374.058,220.938,360.626,207.506,344.058,207.506z"
      />
      <path
        d="M123.57,135.915l33.488-33.488v111.775c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30V102.426l33.488,33.488
 c5.857,5.858,13.535,8.787,21.213,8.787c7.678,0,15.355-2.929,21.213-8.787c11.716-11.716,11.716-30.71,0-42.426L208.271,8.788
 c-11.715-11.717-30.711-11.717-42.426,0L81.144,93.489c-11.716,11.716-11.716,30.71,0,42.426
 C92.859,147.631,111.855,147.631,123.57,135.915z"
      />
    </g>
  </svg>
);

const IconTrashcan = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
  </svg>
);

const PlainButton = props => {
  const [mounted, setMounted] = useState(false);
  const routeConfiguration = useRouteConfiguration();

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    children,
    className,
    rootClassName,
    spinnerClassName,
    checkmarkClassName,
    inProgress,
    ready,
    disabled,
    enforcePagePreloadFor,
    ...rest
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className, {
    [css.ready]: ready,
    [css.inProgress]: inProgress,
  });

  let content;

  if (inProgress) {
    content = <IconSpinner rootClassName={spinnerClassName || css.spinner} />;
  } else if (ready) {
    content = <IconCheckmark rootClassName={checkmarkClassName || css.checkmark} />;
  } else {
    content = children;
  }

  const onOverButtonFn = enforcePreloadOfPage => () => {
    // Enforce preloading of given page (loadable component)
    const { component: Page } = findRouteByRouteName(enforcePreloadOfPage, routeConfiguration);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
  const onOverButtonMaybe = onOverButton
    ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
    : {};

  // All buttons are disabled until the component is mounted. This
  // prevents e.g. being able to submit forms to the backend before
  // the client side is handling the submit.
  const buttonDisabled = mounted ? disabled : true;

  return (
    <button className={classes} {...onOverButtonMaybe} {...rest} disabled={buttonDisabled}>
      {content}
    </button>
  );
};

// Some buttons are link to other pages.
// If enforcePagePreloadFor property is given, lets enhance the Button a bit.
const ButtonWithPagePreload = props => {
  const routeConfiguration = useRouteConfiguration();
  const { enforcePagePreloadFor, ...restProps } = props;

  const onOverButtonFn = enforcePreloadOfPage => () => {
    // Enforce preloading of given page (loadable component)
    const { component: Page } = findRouteByRouteName(enforcePreloadOfPage, routeConfiguration);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
  const onOverButtonMaybe = onOverButton
    ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
    : {};

  return <PlainButton {...restProps} {...onOverButtonMaybe} />;
};

const Button = props => {
  const { enforcePagePreloadFor, ...restProps } = props;
  return enforcePagePreloadFor ? (
    <ButtonWithPagePreload {...props} />
  ) : (
    <PlainButton {...restProps} />
  );
};

Button.defaultProps = {
  rootClassName: null,
  className: null,
  spinnerClassName: null,
  checkmarkClassName: null,
  inProgress: false,
  ready: false,
  disabled: false,
  enforcePagePreloadFor: null,
  children: null,
};

Button.propTypes = {
  rootClassName: string,
  className: string,
  spinnerClassName: string,
  checkmarkClassName: string,

  inProgress: bool,
  ready: bool,
  disabled: bool,
  enforcePagePreloadFor: string,

  children: node,
};

export default Button;

export const PrimaryButton = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonRoot, css.primaryButton);
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButton.displayName = 'PrimaryButton';

export const PrimaryButtonInline = props => {
  const classes = classNames(props.rootClassName || css.primaryButtonInlineRoot, css.primaryButton);
  return <Button {...props} rootClassName={classes} />;
};
PrimaryButtonInline.displayName = 'PrimaryButtonInline';

export const SecondaryButton = props => {
  const classes = classNames(props.rootClassName || css.secondaryButtonRoot, css.secondaryButton);
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const SecondaryButtonInline = props => {
  const classes = classNames(
    props.rootClassName || css.secondaryButtonInlineRoot,
    css.secondaryButtonInline
  );
  return <Button {...props} rootClassName={classes} />;
};
SecondaryButton.displayName = 'SecondaryButton';

export const InlineTextButton = props => {
  const classes = classNames(props.rootClassName || css.inlineTextButtonRoot, css.inlineTextButton);
  return <Button {...props} rootClassName={classes} />;
};
InlineTextButton.displayName = 'InlineTextButton';

export const SocialLoginButton = props => {
  const classes = classNames(props.rootClassName || css.socialButtonRoot, css.socialButton);
  return <Button {...props} rootClassName={classes} />;
};

SocialLoginButton.displayName = 'SocialLoginButton';

export const UploadFileButton = props => {
  const { ownerId, setUploadedFilePaths, setError, children, ...rest } = props;
  const [inProgress, setInProgress] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const intl = useIntl();

  const handleFileChange = async event => {
    // Clear previous upload error messages
    setError(null);

    let file = null;

    if (event.target.files.length > 1) {
      const zip = new JSZip();
      const firstFileName = event.target.files[0].name;
      const fileNameWithoutExtension = firstFileName
        .split('.')
        .slice(0, -1)
        .join('.');

      // Add each file to the zip
      Array.from(event.target.files).forEach(file => {
        zip.file(file.name, file);
      });

      // Generate the zip file
      file = await zip
        .generateAsync({ type: 'blob' })
        .then(
          content =>
            new File([content], fileNameWithoutExtension + '.zip', { type: 'application/zip' })
        )
        .catch(error => {
          setError('An error occurred while zipping the files');
          console.error('An error occurred while zipping the files', error);
          return null;
        });
    } else {
      file = event.target.files[0];
    }

    // If no file was selected or an error occurred while getting the file information, return
    if (file === null) {
      setError(intl.formatMessage({ id: 'UploadFile.unableToUpload' }));
      console.error("Couldn't uploaded the file");
      return null;
    }

    const encodedFileInformation = encodeURIComponent(
      JSON.stringify({ fileName: file.name, fileType: file.type })
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/api/s3/generateUploadUrl?ownerId=${ownerId}&file=${encodedFileInformation}`,
        {
          mode: 'cors',
        }
      );

      const data = await response.json();

      if ('message' in data) {
        setError(data.message);
      } else if ('uploadURL' in data) {
        setInProgress(true);

        const uploadResponse = await fetch(data.uploadURL, {
          method: 'PUT',
          headers: { 'Content-Type': 'multipart/form-data' },
          body: file,
        });

        if (uploadResponse.ok) {
          setUploadComplete(true);
        } else {
          setError(intl.formatMessage({ id: 'UploadFile.uploadError' }, { fileName: file.name }));
        }

        setInProgress(false);
        setUploadedFilePaths(prev => [...prev, { ownerId, fileName: file.name }]);
      } else {
        setError(
          intl.formatMessage({ id: 'UploadFile.uploadImproperResponse' }, { fileName: file.name })
        );
        console.error('Unable to get secure URL from server');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError(intl.formatMessage({ id: 'UploadFile.uploadError' }, { fileName: file.name }));
    }

    // Clear the file input, needed in scenario where user uploads the same file again ( or uploads, cancels and uploads again)
    document.getElementById('fileInput').value = '';
  };

  const classes = classNames(
    props.rootClassName || css.secondaryButtonInlineRoot,
    css.secondaryButtonInline,
    css.uploadFileButton,
    {
      [css.ready]: uploadComplete,
      [css.inProgress]: inProgress,
    }
  );

  let content;

  if (uploadComplete) {
    content = <IconCheckmark rootClassName={css.checkmark} />;
    setTimeout(() => setUploadComplete(false), 1000);
  } else if (inProgress) {
    content = <IconSpinner rootClassName={css.spinner} />;
  } else {
    content = (
      <>
        <IconUploadFile />
        <FormattedMessage id="Button.uploadFileButton" />
      </>
    );
  }

  return (
    <label {...rest} htmlFor="fileInput" className={classes}>
      <input
        type="file"
        id="fileInput"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept="image/*, .pdf, .zip, .ai, .eps, .webp"
        multiple
      />
      {content}
    </label>
  );
};

export const DeleteButton = props => {
  return (
    <Button {...props} rootClassName={classNames(css.deleteButton, props.className)}>
      <IconTrashcan />
      {props.children}
    </Button>
  );
};
