/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React, { useState } from 'react';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { propTypes } from '../../../util/types';
import { types as sdkTypes } from '../../../util/sdkLoader';

import { LINE_ITEM_ADD_ON } from '../../../util/types';
import { bool, object } from 'prop-types';

import classNames from 'classnames';

import { H6 } from '../../Heading/Heading';
import AddOnPriceBreakdown from './components/AddOnPriceBreakdown/AddOnPriceBreakdown';

import css from '../OrderBreakdown.module.css';

const DropdownArrow = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <rect x="0" fill="none" width="24" height="24" />

    <g>
      <path d="M7 10l5 5 5-5" />
    </g>
  </svg>
);

const LineItemAddOnsMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  const addOnLineItems = lineItems.filter(item => item.code.includes(LINE_ITEM_ADD_ON));
  // Filter out line items that are not included for the current user, and line items with 0 as a unit price
  const items = isProvider
    ? addOnLineItems.filter(item => item.includeFor.includes('provider'))
    : addOnLineItems.filter(item => item.includeFor.includes('customer'));

  if (items.length === 0) return null;

  return (
    <div className={css.addOnsPriceBreakdownContainer}>
      <div className={css.showAddOnsPriceBreakdown}>
        <H6
          as="h6"
          className={css.bookingBreakdownTitle}
          style={{ justifyContent: 'space-between', fontWeight: 'var(--fontWeightSemiBold)' }}
        >
          <FormattedMessage id="OrderBreakdown.addOnPriceBreakdown" />
        </H6>
      </div>
      <AddOnPriceBreakdown lineItems={items} intl={intl} />
    </div>
  );
};

LineItemAddOnsMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
  isProvider: bool.isRequired,
};

export default LineItemAddOnsMaybe;
