import React, { Component } from 'react';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import Cookies from 'js-cookie';

import {
  login,
  loginAndUpdateInformation,
  signup,
  signupWithIdp,
  authenticationInProgress,
} from '../../ducks/auth.duck';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { pickUserFieldsData } from '../../util/userHelpers';
import {
  getAuthInfoFromCookies,
  getAuthErrorFromCookies,
  getNonUserFieldParams,
} from '../../containers/AuthenticationPage/AuthenticationPage';

import EmailVerificationInfo from '../../containers/AuthenticationPage/EmailVerificationInfo';
import { AuthenticationOrConfirmInfoForm } from '../../containers/AuthenticationPage/AuthenticationPage';
import TermsAndConditions from '../../containers/AuthenticationPage/TermsAndConditions/TermsAndConditions';
import { TermsOfServiceContent } from '../../containers/TermsOfServicePage/TermsOfServicePage';
import { PrivacyPolicyContent } from '../../containers/PrivacyPolicyPage/PrivacyPolicyPage';

import { camelize } from '../../util/string';
import { FormattedMessage } from 'react-intl';
import { ensureCurrentUser } from '../../util/data';

import {
  TOS_ASSET_NAME,
  PRIVACY_POLICY_ASSET_NAME,
} from '../../containers/AuthenticationPage/AuthenticationPage.duck';

import css from './AuthenticationFormInModal.module.css';

class AuthenticationFormInModalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      currentPage: 'login',
      tosModalOpen: false,
      privacyModalOpen: false,
      authInfo: getAuthInfoFromCookies(),
      authError: getAuthErrorFromCookies(),
    };
    this.handleClose = this.handleClose.bind(this);
    this.changeOpenStatus = this.changeOpenStatus.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.showTosModal = this.showTosModal.bind(this);
    this.showPrivacyModal = this.showPrivacyModal.bind(this);
    this.handleSubmit = this.props.handleSubmit;
  }

  componentDidMount() {
    const { isModalOpen } = this.props;

    if (isModalOpen) {
      this.changeOpenStatus(isModalOpen);
    }

    this.state.authError && Cookies.remove('st-autherror');
  }

  componentDidUpdate(prevProps) {
    const { isModalOpen } = this.props;

    if (isModalOpen !== prevProps.isModalOpen) {
      this.changeOpenStatus(isModalOpen);
    }

    if (this.props.isAuthenticated && this.state.isOpen) {
      this.handleClose();
    }
  }

  changeOpenStatus(isOpen) {
    // If opening the modal, reset the submittedValues
    if (isOpen) this.submittedValues = {};

    this.setState({ isOpen });
  }

  handleClose(event) {
    const { onClose } = this.props;
    this.changeOpenStatus(false);
    if (onClose) {
      onClose(event);
    }

    if (this.props.isAuthenticated) {
      this.props.handleSubmit();
    }
  }

  showTosModal(show) {
    this.setState(prevState => ({ ...prevState, tosModalOpen: show }));
  }

  showPrivacyModal(show) {
    this.setState(prevState => ({ ...prevState, privacyModalOpen: show }));
  }

  handleChangePage(page) {
    this.setState(prevState => ({ ...prevState, currentPage: page }));
  }

  render() {
    const {
      loginError,
      signupError,
      confirmError,
      authInProgress,
      submitLogin,
      submitSignup,
      submitSignupWithIdp,
      className,
      containerClassName,
      closeButtonMessage,
      onManageDisableScrolling,
      usePortal,
      intl,
      shippingAddresses,
      from,
      currentUser,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      onResendVerificationEmail,
      tosAssetsData,
      tosFetchInProgress,
      tosFetchError,
      storeFrontId,
    } = this.props;

    const { isOpen, currentPage, tosModalOpen, privacyModalOpen, authInfo, authError } = this.state;

    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;

    const isLogin = currentPage === 'login';

    // We only want to show the email verification dialog in the signup
    // tab if the user isn't being redirected somewhere else
    // (i.e. `from` is present). We must also check the `emailVerified`
    // flag only when the current user is fully loaded.
    const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;
    const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
      sendVerificationEmailError
    )
      ? 'AuthenticationPage.resendFailedTooManyRequests'
      : 'AuthenticationPage.resendFailed';
    const resendErrorMessage = sendVerificationEmailError ? (
      <p className={css.error}>
        <FormattedMessage id={resendErrorTranslationId} />
      </p>
    ) : null;

    const closedClassName = !isOpen ? css.modalHidden : null;
    const classes = classNames(css.root, className);

    return (
      <>
        <Modal
          className={classes}
          containerClassName={containerClassName || css.modalContainer}
          contentClassName={css.modalContent}
          scrollLayerClassName={css.scrollLayer}
          id="AuthenticationFormInModal"
          isOpen={isOpen}
          isClosedClassName={closedClassName}
          onClose={this.handleClose}
          closeButtonMessage={closeButtonMessage}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal={usePortal && isOpen}
        >
          {showEmailVerification ? (
            <EmailVerificationInfo
              name={user.attributes.profile.firstName}
              email={<span className={css.email}>{user.attributes.email}</span>}
              onResendVerificationEmail={onResendVerificationEmail}
              resendErrorMessage={resendErrorMessage}
              sendVerificationEmailInProgress={sendVerificationEmailInProgress}
              hideProfileLink={true}
            />
          ) : (
            <AuthenticationOrConfirmInfoForm
              tab={currentPage}
              authInfo={authInfo}
              from={from}
              showFacebookLogin={!!process.env.REACT_APP_FACEBOOK_APP_ID}
              showGoogleLogin={!!process.env.REACT_APP_GOOGLE_CLIENT_ID}
              submitLogin={loginCredentials => submitLogin(loginCredentials, shippingAddresses)}
              submitSignup={submitSignup}
              submitSignupWithIdp={submitSignupWithIdp}
              authInProgress={authInProgress}
              loginError={loginError}
              idpAuthError={authError}
              signupError={signupError}
              confirmError={confirmError}
              handleTabSwitch={page => this.handleChangePage(page)}
              termsAndConditions={
                <TermsAndConditions
                  onOpenTermsOfService={() => this.showTosModal(true)}
                  onOpenPrivacyPolicy={() => this.showPrivacyModal(true)}
                  intl={intl}
                />
              }
              shippingAddresses={shippingAddresses}
              storeFrontId={storeFrontId}
            />
          )}
        </Modal>
        <Modal
          id="AuthenticationFormInModal.tos"
          isOpen={tosModalOpen}
          onClose={() => this.showTosModal(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <TermsOfServiceContent
              inProgress={tosFetchInProgress}
              error={tosFetchError}
              data={tosAssetsData?.[camelize(TOS_ASSET_NAME)]?.data}
            />
          </div>
        </Modal>
        <Modal
          id="AuthenticationFormInModal.privacyPolicy"
          isOpen={privacyModalOpen}
          onClose={() => this.showPrivacyModal(false)}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.privacyWrapper}>
            <PrivacyPolicyContent
              inProgress={tosFetchInProgress}
              error={tosFetchError}
              data={tosAssetsData?.[camelize(PRIVACY_POLICY_ASSET_NAME)]?.data}
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError, confirmError } = state.auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const {
    pageAssetsData: privacyAssetsData,
    inProgress: privacyFetchInProgress,
    error: privacyFetchError,
  } = state.hostedAssets || {};
  const { pageAssetsData: tosAssetsData, inProgress: tosFetchInProgress, error: tosFetchError } =
    state.hostedAssets || {};

  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    // scrollingDisabled: isScrollingDisabled(state),
    signupError,
    confirmError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    privacyAssetsData,
    privacyFetchInProgress,
    privacyFetchError,
    tosAssetsData,
    tosFetchInProgress,
    tosFetchError,
    storeFrontId: state.storeFrontInformation.storeFrontInformation?.storeFrontId,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ email, password }, shippingAddresses) =>
    dispatch(
      shippingAddresses.length > 0
        ? loginAndUpdateInformation(email, password, shippingAddresses)
        : login(email, password)
    ),
  submitSignup: params => dispatch(signup(params)),
  submitSignupWithIdp: params => dispatch(signupWithIdp(params)),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  //   onManageDisableScrolling: (componentId, disableScrolling) =>
  //     dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const AuthenticationFormInModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(AuthenticationFormInModalComponent);

export default AuthenticationFormInModal;
