import { getObjectFromDownloadUrl, getDownloadUrl, uploadFileToS3Bucket } from '../util/data';
import { fetchCurrentUser } from './user.duck';

import * as log from '../util/log';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const FETCH_STORE_LOGO_REQUEST = 'app/user/FETCH_STORE_LOGO_REQUEST';

export const UPLOAD_STORE_LOGO_REQUEST = 'app/user/UPLOAD_STORE_LOGO_REQUEST';

export const UPDATE_STORE_LOGO_SUCCESS = 'app/user/UPDATE_STORE_LOGO_SUCCESS';
export const UPDATE_STORE_LOGO_ERROR = 'app/user/UPDATE_STORE_LOGO_ERROR';

export const CLEAR_STORE_LOGO_ERROR = 'app/user/CLEAR_STORE_LOGO_ERROR';

const initialState = {
  // storeFrontInformation which retain the storeFrontId and the store logo file name
  storeFrontInformation: null,
  updateStoreLogoError: null,
  updateStoreLogoInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload, storeFrontId } = action;

  switch (type) {
    // Fetch store logo from user
    case FETCH_STORE_LOGO_REQUEST:
    case UPLOAD_STORE_LOGO_REQUEST: {
      return {
        ...state,
        storeFrontInformation: null,
        updateStoreLogoInProgress: true,
        updateStoreLogoError: null,
      };
    }
    case UPDATE_STORE_LOGO_SUCCESS: {
      return {
        ...state,
        updateStoreLogoInProgress: false,
        storeFrontInformation: { file: payload, storeFrontId },
      };
    }
    case UPDATE_STORE_LOGO_ERROR:
      return {
        ...state,
        storeFrontInformation: undefined,
        updateStoreLogoInProgress: false,
        updateStoreLogoError: payload,
      };
    case CLEAR_STORE_LOGO_ERROR:
      return {
        ...state,
        updateStoreLogoError: null,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //
export const fetchStoreLogoRequest = () => ({ type: FETCH_STORE_LOGO_REQUEST });

export const uploadStoreLogoRequest = () => ({ type: UPLOAD_STORE_LOGO_REQUEST });

export const clearStoreLogoError = () => ({ type: CLEAR_STORE_LOGO_ERROR });

export const updateStoreLogoError = e => ({
  type: UPDATE_STORE_LOGO_ERROR,
  payload: e,
  error: true,
});

const updateStoreLogoSuccess = (storeFrontId, file) => {
  // if (storeFrontId !== null)
  //   // Store the store logo information in local storage
  //   window.localStorage.setItem(
  //     'storeFrontInformation',
  //     JSON.stringify({ storeFrontId, fileName: file?.name || '' })
  //   );

  return {
    type: UPDATE_STORE_LOGO_SUCCESS,
    payload: file,
    storeFrontId,
  };
};

// ================ Thunks ================ //
export const fetchStoreLogoUsingUserId = id => (dispatch, getState, sdk) => {
  console.log("Fetching store logo from user's store");
  if (!id) {
    console.error('Attempted to fetch store data without a storeFrontId');
    dispatch(updateStoreLogoError(storableError(new Error('User not found'))));

    return;
  }

  dispatch(fetchStoreLogoRequest());

  sdk.users
    // This would be a user id which is acting as a store front
    .show({ id })
    .then(res => {
      if (res.status === 200 && res.data.data) {
        return res.data.data;
      } else {
        // NEED TO BETTER HANDLE ERROR
        throw new Error('User not found');
      }
    })
    .then(user => dispatch(fetchStoreFrontInformationUsingUserProfile(user)))
    .catch(error => {
      console.error('An error ocurred while fetching store logo', error);
      if (error instanceof Error) {
        dispatch(updateStoreLogoError(storableError(error)));
      } else if (typeof error === 'string') {
        dispatch(updateStoreLogoError(storableError(new Error(error))));
      } else {
        dispatch(updateStoreLogoError(storableError(new Error('Fetch failed'))));
      }
    });
};

// Used to clear store logo or set to undefined
export const syncStoreLogo = (storeFrontId, file) => (dispatch, getState, sdk) =>
  dispatch(updateStoreLogoSuccess(storeFrontId, file));

export const uploadStoreLogo = (storeFrontId, file, successCallback) => dispatch => {
  // If there is no file, clear the file and call the successCallback
  if (!file) {
    console.log('Clearing store logo image');
    dispatch(updateStoreLogoSuccess(storeFrontId, undefined));

    successCallback();
  } else {
    dispatch(uploadStoreLogoRequest());

    uploadFileToS3Bucket(
      storeFrontId,
      file,
      // Success Callback must be a function declaration, otherwise if supplied dispatch(...), it is called immediately
      () => {
        dispatch(updateStoreLogoSuccess(storeFrontId, file));
        successCallback();
      }
    ).catch(error => {
      console.error('An error ocurred while uploading store logo', error);
      if (error instanceof Error) {
        dispatch(updateStoreLogoError(storableError(error)));
      } else if (typeof error === 'string') {
        dispatch(updateStoreLogoError(storableError(new Error(error))));
      } else {
        dispatch(updateStoreLogoError(storableError(new Error('Upload failed'))));
      }
    });
  }
};

// ================ Helpers ================ //

export const fetchStoreFrontInformationUsingUserProfile = user => dispatch => {
  if (user) {
    const isSupplier = user?.attributes?.profile?.publicData?.storeFrontId === undefined;
    const id = user?.id?.uuid;

    if (isSupplier) {
      const storeLogoFileName = user?.attributes?.profile?.publicData?.storeLogo;
      // If have store logo, fetch the store logo from the server
      if (storeLogoFileName) {
        console.log('Fetching store logo with user data');
        dispatch(handleDownload(id, storeLogoFileName));
      }
      // Supplier does not have a store logo, set the store logo to undefined so the other components may load
      else {
        console.warn('Supplier does not have a store logo');
        dispatch(updateStoreLogoSuccess(id, undefined));
      }
      // This is a buyer which should never be passed to this function
    } else {
      console.error("Buyer's storeFrontId is not null");
      throw new Error('Improper id');
    }
  }
  // API did not return a valid user object
  else {
    console.warn('Unable to get profile information');
    throw new Error('Unable to get profile information');
  }
};

export const getLogoFromDownloadUrl = (
  downloadUrl,
  storeLogoFileName,
  storeFrontId
) => dispatch => {
  return getObjectFromDownloadUrl(downloadUrl, storeLogoFileName, file =>
    dispatch(updateStoreLogoSuccess(storeFrontId, file))
  );
};

// export const dispatchGetStoreLogoWithUserData = (downloadUrl, storeLogo, storeFrontId) => (
//   dispatch,
//   getState,
//   sdk
// ) =>
//   getObjectFromDownloadUrl(downloadUrl, storeLogo, file =>
//     dispatch(updateStoreLogoSuccess(storeFrontId, file))
//   );

// Fetches currentUser and checks if the storeFrontInformation is up to date
export const handleCurrentUserAndCheckStoreFrontInformation = () => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
    dispatch(checkStoreFrontInformation());
    return response;
  });
};

export const checkStoreFrontInformation = () => (dispatch, getState) => {
  const state = getState();
  const currentUser = state.user.currentUser;
  const currentStoreFrontId = state?.storeFrontInformation?.storeFrontInformation?.storeFrontId;

  if (currentUser) {
    const id = currentUser?.id?.uuid;
    const storeFrontId = currentUser?.attributes?.profile?.publicData?.storeFrontId;

    // If the storeFrontInformation does not exist or is out of date
    if (
      !currentStoreFrontId ||
      (currentStoreFrontId && currentStoreFrontId !== (storeFrontId ? storeFrontId : id))
    ) {
      // If this is a buyer account ..
      if (storeFrontId) {
        console.log("Fetching store front information from the supplier's store");
        dispatch(fetchStoreLogoUsingUserId(storeFrontId));
      }
      // If this is a supplier account ...
      else {
        console.log('Fetching store front information from current user');

        dispatch(fetchStoreFrontInformationUsingUserProfile(currentUser));
      }
    }
  }
};

const handleDownload = (storeFrontId, storeLogoFileName) => dispatch =>
  getDownloadUrl(storeFrontId, storeLogoFileName)
    .then(url => dispatch(getLogoFromDownloadUrl(url, storeLogoFileName, storeFrontId)))
    .catch(error => {
      log.error(error, 'fetch-download-url-failed');
      handleError(error);
    });

// Will throw error in proper format
export const handleError = error => {
  if (error instanceof Error) {
    throw error;
  } else if (typeof error === 'string') {
    throw new Error(error);
  }
};
