import React from 'react';

import { isStorableError } from '../../util/types';

import css from './ErrorMessage.module.css';
import classNames from 'classnames';

const ErrorMessage = props => {
  const { error, className, ...rest } = props;

  let errorString = null;
  if (error instanceof Error) {
    errorString = error.message;
  } else if (typeof error === 'string') {
    errorString = error;
  } else if (isStorableError(error)) {
    errorString = error.message;
  }

  return error ? (
    <span className={classNames(css.error, className)} {...rest}>
      {errorString}
    </span>
  ) : null;
};

export default ErrorMessage;
