import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //
export const CLEAR_UPDATED_FORM = 'app/ShippingAddressesPage/CLEAR_UPDATED_FORM';

export const UPDATE_SHIPPING_ADDRESSES_REQUEST =
  'app/ShippingAddressesPage/UPDATE_SHIPPING_ADDRESSES_REQUEST';
export const UPDATE_SHIPPING_ADDRESSES_SUCCESS =
  'app/ShippingAddressesPage/UPDATE_SHIPPING_ADDRESSES_SUCCESS';
export const UPDATE_SHIPPING_ADDRESSES_ERROR =
  'app/ShippingAddressesPage/UPDATE_SHIPPING_ADDRESSES_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveShippingAddressError: null,
  saveShippingAddressInProgress: false,
  shippingAddressChanged: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SHIPPING_ADDRESSES_REQUEST:
      return {
        ...state,
        saveShippingAddressInProgress: true,
        saveShippingAddressError: null,
        shippingAddressChanged: false,
      };
    case UPDATE_SHIPPING_ADDRESSES_SUCCESS:
      return { ...state, saveShippingAddressInProgress: false, shippingAddressChanged: true };
    case UPDATE_SHIPPING_ADDRESSES_ERROR:
      return {
        ...state,
        saveShippingAddressInProgress: false,
        saveShippingAddressError: payload,
      };
    case CLEAR_UPDATED_FORM:
      return {
        ...state,
        saveShippingAddressError: null,
        saveShippingAddressInProgress: false,
        shippingAddressChanged: false,
      };

    default:
      return state;
  }
}

// // ================ Action creators ================ //
export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

export const updateShippingAddressesRequest = params => ({
  type: UPDATE_SHIPPING_ADDRESSES_REQUEST,
  payload: { params },
});

export const updateShippingAddressesSuccess = result => ({
  type: UPDATE_SHIPPING_ADDRESSES_SUCCESS,
  payload: result.data,
});

export const updateShippingAddressesError = error => ({
  type: UPDATE_SHIPPING_ADDRESSES_ERROR,
  payload: error,
  error: true,
});

export const submitShippingAddresses = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateShippingAddressesRequest());

    return sdk.currentUser
      .updateProfile(actionPayload, { expand: true })
      .then(response => {
        dispatch(updateShippingAddressesSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => {
        dispatch(updateShippingAddressesError(storableError(e)));
      });
  };
};
